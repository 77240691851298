import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image"

import "./talk.scss"

const Talk = ({name, summary, year, imageQuery, recordingURL, slidesURL}) => {
  return <div className="talk">
    <h2 className="talk-name">{name}</h2>

    <div className="talk-details">
      <Img fluid={imageQuery.cover.childImageSharp.fluid} alt={name} className="talk-hero"/>
      <section>
        <h3 className="talk-details-title">Talk Details</h3>
        <ul className="talk-detail-list">
          <li>Year: {year}</li>
          {recordingURL && <li><a href={recordingURL} target="_blank" rel="noopener noreferrer">Recording available</a></li>}
          {slidesURL && <li><a href={slidesURL} target="_blank" rel="noopener noreferrer">Slides</a></li>}
        </ul>
        <p className="talk-summary">{summary}</p>
      </section>
    </div>
  </div>
};

Talk.propTypes = {
  name: PropTypes.string,
  summary: PropTypes.string,
  recordingURL: PropTypes.string,
  slidesURL: PropTypes.string,
  year: PropTypes.number,
};

Talk.defaultProps = {
  name: `ConnectSense`,
  summary: `Lorem ipsum sit dolor amet`,
  year: 2018,
};

export default Talk
